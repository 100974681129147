<template>
    <div>
      <CCard>
        <CCardBody>
          <div class="row justify-content-between" v-if="isQuestion == false">
            <div class="col-10">
              <div class="row mb-3">
                <label class="m-1 ml-3" for="">Masukan Jumlah Pertanyaan : </label>
                  <input
                    type="text"
                    v-model="jmlPertanyaan"
                    style="max-width: 200px"
                    class="form-control form-control-sm mx-2"
                    placeholder="Ketik disini"
                  />
                  <button @click="generateTable()" class="btn btn-sm btn-success">
                    Tambah
                  </button>
              </div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-10">
              <button @click="info" class="btn btn-sm btn-primary" v-if="isQuestion == false">
                  Panduan
              </button>
            </div>
            <div class="col-2">
              <div class="row mb-3">
                <button @click="submit" class="btn btn-sm btn-primary" v-if="isQuestion == false">
                  Submit All Tables
                </button>
                <button @click="update" class="btn btn-sm btn-primary" v-else>
                  Update All Tables
                </button>
              </div>
            </div>
          </div>
          <template>
            <div class="container">
              <div class="row">
                <div class="col-12">
                    <div class="table-container">
                      <div v-for="group in listTemplateGroup" :key="group.name" class="table-wrapper col-md-12 col-sm-12">
                        <span style="color: black;"> Desc: {{ group.description }}</span>
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Pertanyaan</th>
                                <template v-for="item in listTemplateSubGroup">
                                    <th v-if="item.groupId == group.id">
                                        Jawaban Untuk {{ item.description }}
                                    </th>
                                </template>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(row, rowIndex) in group.questions" :key="rowIndex">
                              <td>{{ rowIndex + 1 }}</td>
                              <td>
                                <input v-model="row['text' + group.name + rowIndex]" type="text" class="form-control">
                              </td>
                              <template v-for="item in listTemplateSubGroup">
                                <td v-if="item.groupId == group.id">
                                    <input v-model="item.questionDetails[item.description + rowIndex]" type="text" class="form-control">
                                </td>
                              </template>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </template>
        </CCardBody>
      </CCard>
    </div>
  </template>
  
  <script>
  import * as data from "../../model/test-bundle";
  import { uploadImage } from "@/utils/fileUpload";
  import FileSaver from "file-saver";
  
  export default {
    data() {
      return {
        user: JSON.parse(localStorage.getItem("user")),
        params: {
          sorttype: "desc",
          sortby: "id",
          row: 100,
          page: 1,
          keyword: "",
        },
        items: null,
        itemsTotal: 0,
        listTemplateGroup: [],
        listTemplateSubGroup: [],
        templateid: null,
        testId: null,
        isQuestion: false,
        jmlPertanyaan: 0,
        row: null
      };
    },
    methods: {
      submit() {
        var loading = this.$loading.show();
        const questions = {};
        const questionDetails= {};
        const result = {};

        this.listTemplateGroup.forEach((group) => {
            questions[group.name] = [];

            group.questions.forEach((row, rowIndex) => {
                questions[group.name].push(row['text' + group.name + rowIndex]);

                this.listTemplateSubGroup.forEach((item, index) => {
                    if (item.groupId == group.id) {
                        if (!questionDetails[item.id]) {
                            questionDetails[item.id] = [];
                        }
                        questionDetails[item.id].push(item.questionDetails[item.description + rowIndex]);
                    }
                });
            });
        });
        const dataKirim = {
            templateId : this.templateid,
            groupId: this.testId,
            dataGroup : this.listTemplateGroup,
            dataSubGroup : this.listTemplateSubGroup,
            dataQuestions : questions,
            dataQuestionDetails : questionDetails
        };
        // console.log(dataKirim);
  
        this.$store
          .dispatch("test_question/addQuestionMbti", dataKirim)
          .then(() => {
            this.$toast.success("Berhasil menambahkan data");
            loading.hide();
            this.$router.push({ path: 'test-group'});
          })
          .catch((e) => {
            loading.hide();
          });
      },
      update() {
        var loading = this.$loading.show();
        const questions = {};
        const questionsByGroup = {};
        const questionDetails= {};
        const questionsBySubGroup = {};
  
        this.listTemplateGroup.forEach((data) => {
          questionsByGroup[data.name] = [];
        });
        this.items.forEach((item) => {
          this.listTemplateGroup.forEach((data) => {
            if (item.test_template_groups_id == data.id) {
              questionsByGroup[data.name].push(item.id);
            }
          });
        });
        this.listTemplateSubGroup.forEach((data) => {
          questionsBySubGroup[data.id] = [];
        });
        this.items.forEach((item) => {
            item.testquestiondetails.forEach((items) => {
                this.listTemplateSubGroup.forEach((data) => {
                    if (items.test_template_sub_groups_id == data.id) {
                    questionsBySubGroup[data.id].push(items.id);
                    }
                });
            });
        });
        this.listTemplateGroup.forEach((group, index) => {
            questions[group.name] = [];

            group.questions.forEach((row, rowIndex) => {
                questions[group.name].push(row['text' + group.name + rowIndex]);
                this.listTemplateSubGroup.forEach((item) => {
                    if (item.groupId === group.id) {
                        if (!questionDetails[item.id]) {
                            questionDetails[item.id] = [];
                        }
                        questionDetails[item.id].push(item.questionDetails[item.description + rowIndex]);
                    }
                });
            });
        });
        
        const dataUpdate = {
            templateId : this.templateid,
            groupId: this.testId,
            dataGroup : this.listTemplateGroup,
            dataSubGroup : this.listTemplateSubGroup,
            dataQuestions : questions,
            dataQuestionsId : questionsByGroup,
            dataQuestionDetails : questionDetails,
            dataQuestionDetailsId : questionsBySubGroup
        };
        // console.log(dataUpdate);
        
  
        this.$store
          .dispatch("test_question/updateQuestionMbti", {
              id: this.testId,
              data: dataUpdate,
            })
          .then(() => {
            this.$toast.success("Berhasil menambahkan data");
            loading.hide();
            this.$router.push({ path: 'test-group'});
          })
          .catch((e) => {
            loading.hide();
          });
      },
      getData() {
        var loading = this.$loading.show();
        this.$store
          .dispatch("test_question/getQuestion", this.testId)
          .then((resp) => {
            this.items = resp.data;
            this.itemsTotal = resp.total;
            if (this.items !== "kosong") {
                this.isQuestion = true;
                const rows = new Array(parseInt(this.itemsTotal)).fill(null);
                
                this.listTemplateGroup.forEach((group) => {
                    const matchingItems = this.items.filter(item => Number(item.test_template_groups_id) === Number(group.id));
                    
                    if (matchingItems.length > 0) {
                        const numRows = Math.min(this.itemsTotal, matchingItems.length);
                        group.questions = Array.from({ length: numRows }, (_, rowIndex) => {
                            if (rowIndex < numRows) {
                                return { ['text' + group.name + rowIndex]: matchingItems[rowIndex].question };
                    } else {
                      return {};
                    }
                });
                
                const newArray = {};
                matchingItems.forEach((item, index) => {
                    item.testquestiondetails.forEach((detail) => {
                        const key = detail.testtemplatetsubgroup.description + index;
                        newArray[key] = detail.question_detail;
                    });
                });
                const groupedData = {};
                for (const key in newArray) {
                    if (!groupedData[key]) {
                      groupedData[key] = {};
                    }
                    groupedData[key] = newArray[key];
                }
                
                for (const key in groupedData) {
                    if (groupedData.hasOwnProperty(key)) {
                        const description = key.replace(/\d+/g, '');
                        const matchingSub = this.listTemplateSubGroup.find(sub => sub.description == description);
                        if (matchingSub) {
                            Object.assign(matchingSub.questionDetails, { [key]: groupedData[key] });
                        }
                    }
                }
                console.log(this.listTemplateSubGroup);

                } else {
                    group.questions = [...rows];
                }
              });
            //   console.log(this.listTemplateSubGroup);
              // console.log(JSON.stringify(this.listTemplateGroup, null, 2));
            }
            loading.hide();
          })
          .catch((e) => {
            this.$toast.error("gagal mengambil data  \n", e);
            loading.hide();
          });
      },
      getDataGroup() {
        var loading = this.$loading.show();
  
        this.$store
          .dispatch("template/getTemplateGroup", this.templateid)
          .then((resp) => {
            // console.log(this.listTemplateGroup);
            this.listTemplateGroup = resp.data.map(group => ({
                id: group.id,
                name: group.name,
                description: group.description,
                questions: [],
            }));
            this.getDataSubGroup();
            loading.hide();

            // setTimeout(() => {
            //   this.getData();
            //   loading.hide();
            // }, 5000);
          })
          .catch((e) => {
            this.$toast.error("gagal mengambil data  \n", e);
            loading.hide();
          });
      },
      getDataSubGroup() {
        var loading = this.$loading.show();
  
        this.$store
          .dispatch("template/getTemplateSubGroup", this.templateid)
          .then((resp) => {
            this.listTemplateSubGroup = resp.data.map(group => ({
                id: group.id,
                name: group.name,
                groupId: group.test_template_group_id,
                description: group.description,
                questionDetails: [],
            }));

            for (const subgroup of this.listTemplateSubGroup) {
                const matchingGroup = this.listTemplateGroup.find(group => group.id === subgroup.groupId);
                if (matchingGroup) {
                    matchingGroup.subgroups = matchingGroup.subgroups || [];
                    matchingGroup.subgroups.push(subgroup);
                }
            }
            // console.log(this.listTemplateGroup);
            this.getData();
            loading.hide();

            // setTimeout(() => {
            //   this.getData();
            //   loading.hide();
            // }, 5000);
          })
          .catch((e) => {
            this.$toast.error("gagal mengambil data  \n", e);
            loading.hide();
          });
      },
      generateTable() {
        const rows = new Array(parseInt(this.jmlPertanyaan)).fill({});
  
        this.listTemplateGroup.forEach((group) => {
          group.questions = [...rows];
        });
      },
      info() {
          this.$swal.fire({icon: 'info', html: 'Silahkan masukan jumlah pertanyaan yang akan di buat!'});
      }
    },
    computed: {
  
    },
    mounted() {
      
    },
    created() {
      this.templateid = this.$route.query.templateid;
      this.testId = this.$route.query.testId;
      this.getDataGroup();
      // console.log(templateid);
      // console.log(testId);
    },
  };
  </script>
  
  <style scoped>
  .table-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .table-wrapper {
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 20px;
    box-sizing: border-box;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
    text-align: center;
  }
  
  caption {
    font-weight: bold;
    text-align: center;
    padding: 10px;
    position: relative;
    top: -10px;
  }
  
  th, td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  </style>